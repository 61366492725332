
import { lazy } from 'react';


export default class BBSideMenuDescriptor {



static getDescription() {
    return {
      name: 'BBSideMenu',
      component: lazy(() =>  import('./BBSideMenu')),
      label: 'BBSideMenu',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons ()',
        },
        {
          name: 'tabs',
          type: 'array',
          label: 'Tabs',
        },
        {
          name: 'tabSelectionSubscription',
          type: 'text',
          label: 'Variabele to listen to for tab selection',
        },
        {
          name: 'hideTabs',
          type: 'text',
          label:
            'Variabele to listen to for tab visualisation. Use tab names to hide the tabs',
        },
        {
          name: 'icons',
          type: 'array',
          label:
            'fa icon names for each tab such as box,cogs,coffee,envelope,hand-point-left,arrow-left,cube,database,gavel,gift,stop,',
        },
        {
          name: 'headertext',
          type: 'text',
          label: 'text for the header when expanded',
        },
        {
          name: 'lazyLoadingPages',
          type: 'boolean',
          label: 'does not load the tabs/pages in advance. Also returning to a page results in a reload',
        },

        {
          name: 'footertext',
          type: 'text',
          label: 'text for the footer',
        },
        {
          name: 'backgroundimage',
          type: 'text',
          label: 'background image',
        },
        {
          name: 'startCollapsed',
          type: 'boolean',
          label: 'start collapsed',
        },
        {
          name: 'onlyCollapsed',
          type: 'boolean',
          label: 'only collapsed',
        },

        
        {
          name: 'className',
          type: 'text',
          label: 'css classNames',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
      ],
    }
  }

}