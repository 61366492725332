import axios from 'axios'
import React, { Component } from 'react'
import styles from '../../Project.module.css'
import { parseTextForVariables } from '../../../helpers/sparql'

class BBSimplePushButton extends Component {
  state = { enabled: true }
  static getDescription() {
    return {
      name: 'BBSimplePushButton',
      component: BBSimplePushButton,
      label: 'BB Simple Push Button',
      variables: [
        {
          name: 'label',
          type: 'text',
          label: 'label',
        },
        {
          name: 'query',
          type: 'yasgui',
          label: 'for parameters for label for example',
        },
        {
          name: 'link',
          type: 'text',
          label: 'simple link which will be called when the button is clicked',
        },
        {
          name: 'postLink',
          type: 'text',
          label:
            'alternative to link: a post link with optionally a postObject. keywords allowed such as command for command servlet calls',
        },
        {
          name: 'postObject',
          type: 'text',
          label:
            'object send via postLink adres. Parameters will be resolved before sending. keep it simple. no subchildren',
        },
        {
          name: 'postDownload',
          type: 'boolean',
          label: 'triggers a download',
        },
        {
          name: 'downloadFileName',
          type: 'text',
          label: 'download file name ',
        },
        
        {
          name: 'changeParameter',
          type: 'text',
          label: 'change parameter value when succesfull',
        },
        {
          name: 'setInitialValueChangeParameter',
          type: 'boolean',
          label: 'set initial value for change parameter ',
        },
        {
          name: 'successMessage',
          type: 'text',
          label: 'shows message after successful operation ',
        },
        {
          name: 'fontSize',
          type: 'text',
          label: 'size of the text ',
        },
        {
          name: 'sparqlqueryrapport',
          type: 'yasgui',
          label: 'sparql query rapport. (optionally use #template:)',
        },
      ],
    }
  }
  componentDidMount() {
    if (this.props.changeParameter != null) {
      if (this.props.setInitialValueChangeParameter === true) {
        if (this.props[this.props.changeParameter] == null) {
          this.props.publish(this.props.changeParameter, 'start')
        }
      }
    }
  }

  onClick2 = (event) => {
    alert('already busy')
  }

  onClick = (event) => {
    if (this.props.sparqlqueryrapport != null) {
      this.sendSparqlQueryRapport()
      return
    }

    let url = this.props.link
    if (url != null) {
      this.sendSimpleLink(url)
      return
    }
    url = this.props.postLink
    if (url != null) {
      this.sendPostLink(url)
      return
    }
  }

  sendSparqlQueryRapport() {
    // console.log("create sparql report");
    let sparql = this.props.sparqlqueryrapport

    let sparql2 = parseTextForVariables(sparql, this.props)
    // console.log(sparql2);
    let url = window.top.url
    url = url.replace('sparql', 'command')

    const params = new URLSearchParams()
    params.append('commando', 'sparqlReport')
    params.append('sparql', sparql2)

    //let o = {commando: "sparqlReport", sparql:sparql2};
    this.reallySend(url, params)
  }
  sendPostLink(url) {
    let data = this.props.postObject
    if (data == null) data = {}
    

    if (url === 'command') {
      url = window.top.url
      url = url.replace('sparql', 'command')
    }

    const params = new URLSearchParams()

    try {
      data = JSON.parse(data)
      for (let key in data) {

        let value = parseTextForVariables(data[key], this.props);
        value=encodeURI(value);
        params.append(key,value );
        //console.log("appending ",key,data[key]);
      }
    } catch (error) {
      console.log('JSON command not ok', data)
      console.log(error)
    }
    this.reallySend(url, params)
  }
  reallySend(url, params) {
    var me = this
    var ajax = new XMLHttpRequest()

    ajax.open('POST', url, true)
    ajax.withCredentials = true
    ajax.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    ajax.responseType = 'blob'
    ajax.onreadystatechange = function (e) {
      // Call a function when the state changes.
      if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        // Request finished. Do processing here.
        console.log('downloading 22 ', e)
        //  me.download(e.target.response, "fileName.xslx", "application/octet-stream");
      } else {
        console.log('ready state ', this.readyState)
        if (this.readyState === XMLHttpRequest.DONE && this.status !== 200) {
          console.log('readystate changed')
          //alert("error: cannot proceed");
        }
      }
    }
    var filename='fileName2.xslx';
    if (this.props.downloadFileName!=null) filename=this.props.downloadFileName;

    ajax.onload = function (e) {
      console.log(e.target.response.type)
      if (e.target.response.type === 'text/xml') {
        alert('could not download document')
      } else {
        me.download(
          e.target.response,
          filename,
          'application/octet-stream'
        )
      }
    }
    setTimeout(function () {
      ajax.send(params)
    }, 0)

    if (this.props.changeParameter != null) {
      var v = Math.random(100000)
      // console.log("publishing ",this.props.changeParameter,v);
      this.props.publish(this.props.changeParameter, v)
    }
    this.setState({ enabled: true })
  }

  sendSimpleLink(url) {
    // console.log(url);
    this.setState({ enabled: false })
    axios({
      method: 'get',
      url: url,
    }).then(
      (response) => {
        if (this.props.changeParameter != null) {
          var v = Math.random(100000)
          // console.log("publishing ",this.props.changeParameter,v);
          this.props.publish(this.props.changeParameter, v)
        }
        this.setState({ enabled: true })
      },
      (error) => {
        console.log(error)
        alert(error)
        this.setState({ enabled: true })
      }
    )
  }

  render() {
    let label = this.props.label
    if (label == null) label = 'geen label'
    let enabled = this.state.enabled

    try {
      let prop = { pubsub: {} }
      let o = this.props.data.results.bindings[0]
      for (let key in o) {
        prop.pubsub[key] = o[key].value
      }

     
      label = parseTextForVariables(label, prop)
    } catch (e) {
      //console.log(e)
    }
    let size = this.props.fontSize
    if (size == null || size === '') {
      size = '10'
    }
    size += 'px'

    let divStyle = { fontSize: size }

    return (
      <div>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        {enabled ? (
          <div className={styles.viewer} onClick={this.onClick}>
            <div style={divStyle} className={styles.titleText}>
              {label}
            </div>
          </div>
        ) : (
          <div
            className={styles.viewer + ' ' + styles.viewerDisabled}
            onClick={this.onClick2}
          >
            <div style={divStyle} className={styles.titleText}>
              {label}
            </div>
          </div>
        )}
      </div>
    )
  }

  download(data, strFileName, strMimeType) {
    var self = window, // this script is only for browsers anyway...
      defaultMime = 'application/octet-stream', // this default mime also triggers iframe downloads
      mimeType = strMimeType || defaultMime,
      payload = data,
      url = !strFileName && !strMimeType && payload,
      anchor = document.createElement('a'),
      toString = function (a) {
        return String(a)
      },
      myBlob = self.Blob || self.MozBlob || self.WebKitBlob || toString,
      fileName = strFileName || 'download',
      blob,
      reader
    myBlob = myBlob.call ? myBlob.bind(self) : Blob

    if (String(this) === 'true') {
      //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
      payload = [payload, mimeType]
      mimeType = payload[0]
      payload = payload[1]
    }

    if (url && url.length < 2048) {
      // if no filename and no mime, assume a url was passed as the only argument
      fileName = url.split('/').pop().split('?')[0]
      anchor.href = url // assign href prop to temp anchor
      if (anchor.href.indexOf(url) !== -1) {
        // if the browser determines that it's a potentially valid url path:
        var ajax = new XMLHttpRequest()
        ajax.open('GET', url, true)
        ajax.responseType = 'blob'
        ajax.onload = function (e) {
          this.download(e.target.response, fileName, defaultMime)
        }
        setTimeout(function () {
          ajax.send()
        }, 0) // allows setting custom ajax headers using the return:
        return ajax
      } // end if valid url?
    } // end if url?

    //go ahead and download dataURLs right away
    if (/^data:[\w+-]+\/[\w+-]+[,;]/.test(payload)) {
      if (payload.length > 1024 * 1024 * 1.999 && myBlob !== toString) {
        payload = dataUrlToBlob(payload)
        mimeType = payload.type || defaultMime
      } else {
        return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
          ? navigator.msSaveBlob(dataUrlToBlob(payload), fileName)
          : saver(payload) // everyone else can save dataURLs un-processed
      }
    } //end if dataURL passed?

    blob =
      payload instanceof myBlob
        ? payload
        : new myBlob([payload], { type: mimeType })

    function dataUrlToBlob(strUrl) {
      var parts = strUrl.split(/[:;,]/),
        type = parts[1],
        decoder = parts[2] === 'base64' ? atob : decodeURIComponent,
        binData = decoder(parts.pop()),
        mx = binData.length,
        i = 0,
        uiArr = new Uint8Array(mx)

      for (i; i < mx; ++i) uiArr[i] = binData.charCodeAt(i)

      return new myBlob([uiArr], { type: type })
    }

    function saver(url, winMode) {
      if ('download' in anchor) {
        //html5 A[download]
        anchor.href = url
        anchor.setAttribute('download', fileName)
        anchor.className = 'download-js-link'
        anchor.innerHTML = 'downloading...'
        anchor.style.display = 'none'
        document.body.appendChild(anchor)
        setTimeout(function () {
          anchor.click()
          document.body.removeChild(anchor)
          if (winMode === true) {
            setTimeout(function () {
              self.URL.revokeObjectURL(anchor.href)
            }, 250)
          }
        }, 66)
        return true
      }

      // handle non-a[download] safari as best we can:
      if (
        /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(
          navigator.userAgent
        )
      ) {
        url = url.replace(/^data:([\w/\-+]+)/, defaultMime)
        if (!window.open(url)) {
          // popup blocked, offer direct download:
          if (
            alert(
              'Displaying New Document\n\nUse Save As... to download, then click back to return to this page.'
            )
          ) {
          }
        }
        return true
      }

      //do iframe dataURL download (old ch+FF):
      var f = document.createElement('iframe')
      document.body.appendChild(f)

      if (!winMode) {
        // force a mime that will download:
        url = 'data:' + url.replace(/^data:([\w/\-+]+)/, defaultMime)
      }
      f.src = url
      setTimeout(function () {
        document.body.removeChild(f)
      }, 333)
    } //end saver

    if (navigator.msSaveBlob) {
      // IE10+ : (has Blob, but not a[download] or URL)
      return navigator.msSaveBlob(blob, fileName)
    }

    if (self.URL) {
      // simple fast and modern way using Blob and URL:
      saver(self.URL.createObjectURL(blob), true)
    } else {
      // handle non-Blob()+non-URL browsers:
      if (typeof blob === 'string' || blob.constructor === toString) {
        try {
          return saver('data:' + mimeType + ';base64,' + self.btoa(blob))
        } catch (y) {
          return saver('data:' + mimeType + ',' + encodeURIComponent(blob))
        }
      }

      // Blob but not URL support:
      reader = new FileReader()
      reader.onload = function (e) {
        saver(this.result)
      }
      reader.readAsDataURL(blob)
    }
    return true
  } /* end download() */
}
export default BBSimplePushButton
