import './bbutil'
import BBIFrame from './BBIFrame'

class BBTable extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'true'
    this.noEvents = false
    this.usesParameters = false
  }

  getParameters() {
    return this.props.wraptext ? "?wraptext=true" : ""
  }

  static getDescription() {
    return {
      name: 'BBTable',
      component: BBTable,
      label: 'BB Table widget',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the map. Use ?uri and ?geometry for WKT strings. Optionally use ?color for automatic coloring ',
        },
        {
          name: 'renderEmpty',
          type: 'boolean',
          label: 'render an empty table',
        },
        {
          name: 'colSizes',
          type: 'array',
          label: 'optional array of col sizes. 100px,50%,350px. Needs a widgetrefresh when changed',
        },
        
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Published uri variabele for other widgets.',
        },
       
        
        {
          name: 'replaceHeaderJSON',
          type: 'text',
          label:
            'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
        },
        {
          name: 'searchbar',
          type: 'boolean',
          label:'show searchbar',
        },
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
        {
          name: 'wraptext',
          type: 'boolean',
          label: 'wrap text in the table',
        }
      ],
    }
  }

  getUrl() {
    // console.log("BB Table should getURL");

    return '/bb/w2ui/table.html'
  }
}

export default BBTable
