import { lazy } from 'react';
export default class ButtonPopupDescriptor  {

static getDescription() {
    return {
      name: 'BB ButtonPopup',
      component: lazy(() =>  import('./ButtonPopup')),
      label: 'Button Popup',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'button label',
        },
        {
          name: 'beginIcon',
          type: 'text',
          label: 'begin icon',
        },
        {
          name: 'endIcon',
          type: 'text',
          label: 'end icon',
        },

        {
          name: 'popupFullScreen',
          type: 'boolean',
          label: 'popup fullScreen not implemented',
        },
        {
          name: 'popupTitle',
          type: 'text',
          label: 'popupTitle',
        },

        {
          name: 'tooltip',
          type: 'text',
          label: 'button tooltip',
        },
        {
          name: 'disable',
          type: 'text',
          label: 'disable when parameter is empty',
        },
        {
          name: 'closeAtParameterChange',
          type: 'text',
          label: 'close popup when the parameter changes. deprecated',
        },
        {
          name: 'openDialog',
          type: 'boolean',
          label: 'open dialog the first time',
        },
      ],
    }
  }
}