
import { lazy } from 'react';

export default class BBDWegselectorDescriptor  {
 
  static getDescription() {
    return {
      name: 'Wegselector',
      component: lazy(() =>  import('./BBWegselector')),
      label: 'Wegselector',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de form',
        },
        
        
        {
          name: 'showStrook',
          type: 'boolean',
          label: 'show strook options',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Published filter sparql bps into a parameter',
        }
     ]
    }
  }

  
}
