
import { lazy } from 'react';
import PublishUtils from '../../../helpers/PublishUtils'

export default class BBUploadDescriptor  {
 
  



 static getDescription() {
    return {
      name: 'BBUpload',
      component: lazy(() =>  import('./BBUpload')),
      label: 'BB Upload widget',
      variables: [
        {
          name: 'serverUrl',
          type: 'text',
          label: 'server url (full url or upload)',
        },
        {
          name: 'command',
          type: 'text',
          label: 'command for server  {validateGarantie}',
        },
        {
          name: 'uploadText',
          type: 'text',
          label: 'text for the widget',
        },
        {
          name: 'processingText',
          type: 'text',
          label: 'text for the widget when uploading and processing',
        },
        {
          name: 'fileExtensions',
          type: 'text',
          label: 'fileExtensions xslx,js',
        },
        {
          name: 'fields',
          type: 'json',
          label: 'json for w2uiform fields',
        },
        {
          name: 'tabs',
          type: 'text',
          label: 'optional w2ui tabs definitie',
        },

        {
          name: 'multiple',
          type: 'boolean',
          label: 'multiple files ',
        },
        {
          name: 'extraJSON',
          type: 'text',
          label: 'extra json info. parameters may be used. {"graph":"{{graph}}"}',
        },
        {
          name: 'autoUpload',
          type: 'boolean',
          label: 'auto upload ',
        },
        {
          name: 'successEval',
          type: 'text',
          label:
            'javascript code after successfull upload... e.g. me.downloadReport("garantierapport","garantie.html") ',
        },

        PublishUtils.getTimestampParametersDef(),
        
      ],
    }
  }
}