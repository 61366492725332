import { lazy } from 'react';

export default class BBIFCJSViewerDescriptor{


static getDescription() {
    return {
      name: 'IFCJSViewer',
      component: lazy(() =>  import('./BBIFCJSViewer.js')),
      label: 'IFC JS Viewer (v2)',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?filename ?guid ?hidden ?alpha ?color  ',
        }]
    }
}
}