import { lazy } from 'react';

import PublishUtils from './../../helpers/PublishUtils'

export default class TreeDescriptor {
  

    static getDescription() {
        return {
          name: 'Tree',
          component: lazy(() =>  import('./Tree')), 
          label: 'Tree widget',
          variables: [
            {
              name: 'query',
              type: 'yasgui',
              label:
                'Query to fill tree. First item should be the object label, the second the object URI, the third the parent URI. optional you can use icon, icon2 and icon3 variables for fa-fa icons and checkbox for checkbox version',
            },
            {
              name: 'publishVariable',
              type: 'text',
              label:
                'Variabele in which tree item URI is published, when tree item is clicked.',
            },
            {
              name: 'publishVariable2',
              type: 'text',
              label:
                'Variabele in which tree item URI2 is published, when tree item is clicked.',
            },
            {
              name: 'subscribeVariable',
              type: 'text',
              label:
                'Publish variabele on which to listen to to define tree item selection.',
            },
            {
              name: 'rootLabel',
              type: 'text',
              label: 'Label of the root of the tree. Leave empty for no root.',
            },
            
            {
              name: 'expandLevel',
              type: 'int',
              label: 'initially expand the tree to this level',
            },
    
            {
              name: 'title',
              type: 'text',
              label: 'title',
            },
            {
              name: 'defaultIcon',
              type: 'text',
              label: 'fa icon name when no icon is defined. ',
            },
            {
              name: 'aggregate',
              type: 'boolean',
              label: 'Aggregate the results based upon parent and child uri ',
            },
            {
              name: 'shouldNonSelectedSubTreeClose',
              type: 'boolean',
              label:
                'Should a subtree that does not contain selection fold on new selection.',
            },
            {
              name: 'setSelectionInURL',
              type: 'boolean',
              label: 'the selected URI will be used in the browser URL. THIS WORKS ONLY WHEN browser URL is similar to the selection (Security reasons)',
            },
            {
              name: 'debugSetSelectionInURL',
              type: 'boolean',
              label: 'sets a ?selection parameter in the browser url for Debug reasons',
            },
            {
              name: 'setSelectionInURLStartsString',
              type: 'text',
              label:
                'the selected URI will be used in the browser URL only if it starts with this text. . ',
            },
            {
              name: 'parseInitialURLForSelection',
              type: 'boolean',
              label: 'parse the initial URL for selection',
            },
            {
              name: 'initialSelection',
              type: 'text',
              label: 'initial uri selection',
            },
            {
              name: 'scrollSelectionIntoView',
              type: 'boolean',
              label: 'try to automatically scroll the selected object into view',
            },
            {
              name:'multipleCheckBoxParameter',
              type:'text',
              label:"parameter for (multiple) checkbox values "
            }
              ,
            PublishUtils.getMultipleValueFormatOptions(),
          ]
        }
      }
    
}