
import { lazy } from 'react';
export default class BBDCheckboxTreeDescriptor  {
 
  static getDescription() {
    return {
      name: 'BBCheckboxTree',
      component: lazy(() =>  import('./BBCheckboxTree')),
      label: 'BBCheckboxTree',
      variables: [
        
        {
          name: 'query',
          type: 'yasgui',
          label:'to fill the tree.   ?uri ?text ?parent ?icon ',
        },
       
      ],
    }
  }

  
}
