import './bbutil'
import BBIFrame from './BBIFrame'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'

class BBMultiMap extends BBIFrame {
  constructor() 
  {
    super()
    //console.log("BBMap Constructor");
    this.scrolling = 'no'
    this.noEvents = false
  }

  static getDescription() {
    return {
      name: 'BBMultiMap',
      component: BBMultiMap,
      label: 'Leaflet Multi map',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the multimap. Use ?uri ?label ?graph ?colorPred ?colorPredLabel ?labelPred ?labelPredLabel ?selectedColorPred ?selectedLabelPred ?extra for sparql definitions',
        },
        {
          name: 'useSparqlMapDefinitions',
          type: 'boolean',
          label: 'use the sparql results for multiple map definitions',
        },
        
        {
          name: 'height',
          type: 'text',
          label: 'height variable',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'subscribeExtraSparql',
          type: 'text',
          label:
            'parameter containing extra sparql ',
        },
       
        {
          name: 'allQueries',
          type: 'text',
          label:'parametername containing an array of all queries (not yet implemented, useful for downloadGIS function)',
        },

        AfterJS.getJSScriptAfterSelectionChange(),
        PublishUtils.getResetValueDef(),
        
      ],
    }
  }

  state = { selectedUri: null }

  getUrl() {
    //console.log("BBMap getURL");

    return '/bb/multimap/leafletWidgetDeploy171V1.html'
  }
}
export default BBMultiMap
