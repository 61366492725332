import { lazy } from 'react';

export default class BBIFCJSViewerDescriptor{


static getDescription() {
    return {
      name: 'BBThreeJSViewer',
      component: lazy(() =>  import('./BBThreeJSViewer.js')),
      label: 'BB ThreeJS viewer (v2)',
      variables: [
        {
          name: 'glb files prefix',
          type: 'text',
          label: 'prefix for all glb files (e.g. http://wistor.nl/BloodBee/cgi/download/) ',
        },
        {
          name: 'glb files',
          type: 'array',
          label: 'comma separated file names to load. "file1.glb,file2.glb" or use parameters {{filename}}',
        },
        {
          name: 'visualisation hint',
          type: 'select',
          options: [
            
            { label: 'normal', value: 'normal' },
            { label: 'transparent', value: 'transparent' },
            { label: 'very transparent', value: 'vtransparent' },
            { label: 'toon', value: 'toon' },
            { label: 'toon transparent', value: 'toontransparent' },
            { label: 'nice', value: 'nice' },
            { label: 'grey', value: 'grey' },
            { label: 'grey transparent', value: 'greytransparent' },
            { label: 'grey very transparent', value: 'greyverytransparent' },




            
          ],
          label: 'such as transparent,normal,toon ',

        },

        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?guid ?uri ?hidden ?alpha ?color ?label. use sparqlhints #transparent   ',
        },
      
        {
          name: 'guidpublishparameter',
          type: 'text',
          label: 'parameter for guid publishing',
        },
        {
          name: 'guidselector',
          type: 'text',
          label: 'parameter for selecting object using GUID',
        }
      
      
      ]
    }
}
}