import { Component } from 'react'

import ReactDOM from 'react-dom'
import { generateEmptyResponse } from '../../../helpers/sparql'
import './BBIFrame.css'

import throttle from 'lodash/throttle'
import PublishUtils from '../../../helpers/PublishUtils'
import AfterJS from '../../../helpers/AfterJS'
import { parseTextForVariables } from '../../../helpers/sparql'
import './bbutil'
import {getSparqlFunction} from '../../../helpers/utils'

import { SET_USER_ACTIONS_DISABLED } from '../../../redux/project'


import shallowCompare from 'react-addons-shallow-compare'; 

import _ from 'lodash';

class BBIFrame extends Component {
  state = {
    imgViz: false,
    selectedUri: null,
    reloadID: 1,
  }

  static htmln = 0
  static scrolling = 'no'
  noEvents = false

  uploadSuccessfulAfter() {
    //console.log("after upload");
    PublishUtils.setTimeStampParameter(this, true)
  }

   executeSparql(sparql,rv){

    
     let qq =parseTextForVariables(sparql, this.props);
     
      if (this.query==null)
      {
        this.query=getSparqlFunction(this.props);
      }



    this.query(qq,function(arg){
       
       if (rv!=null) rv.call(this,arg);}
       ,function(arg2){
        console.log(arg2);});
     

   }

  onClick = (event, uri) => {
   // console.log(event,uri);
    var me = this
    if (uri == null) {
      uri = event
    }
    if (
      event != null &&
      event === 'http://www.bbsgroep.nl/application/v2/hasTextOutput'
    ) {
      me.noEvents = true
      var pvar = me.props.publishTextVariable
      if (pvar != null) {
        me.props.publish(pvar, uri)
        //PublishUtils.resetParameter(me);
      }
      me.noEvents = false

      return
    }
    if (
      event != null &&
      event === 'http://www.bbsgroep.nl/application/v2/hasVar1Output'
    ) {
      me.noEvents = true
      pvar = me.props.publishVariable1
      if (pvar != null) {
        if (uri !== event) {
          me.props.publish(pvar, uri)
        }
        //PublishUtils.resetParameter(me);
      }
      me.noEvents = false

      return
    }

    //console.log("continuing");
    me.noEvents = true
    pvar = me.props.publishVariable
    //console.log(pvar);
    if (pvar != null) {
     //   console.log("event iframe, setting variable ",pvar,uri,me)
      me.props.publish(pvar, uri)
      //  console.log("run after selection  ",AfterJS);
      try {
        AfterJS.runAfterSelection(me)
      } catch (e) {
        console.log(e)
      }
      // console.log("run after selection ended");
      PublishUtils.resetParameter(me)
    }
    me.noEvents = false
  }

  getUrl() {
    return ''
  }

  componentDidMount() {
    var me = this
    this.mounted = true
    // var iframe=ReactDOM.findDOMNode(this);
    var div = ReactDOM.findDOMNode(this).parentNode

    div.setAttribute('style', 'position:relative;')

    if (this.iface != null) {
      try {
        if (me.iface.afterIntegration != null) {
          me.iface.afterIntegration(this.props)
        }
      } catch (e) {
        console.log(e)
      }
      // console.log("registerMe componentDitMount "+this.getUrl());



      this.updateMe(null)
    }
  }
  shouldComponentUpdate(arg, arg2) {
    // console.log(Component.prototype.shouldComponentUpdate.call(this,arg,arg2));
    //console.log(this,Component.prototype.shouldComponentUpdate.call(this,arg,arg2));
    //console.log("should iframe update ",this.mounted,this.iface);

    if (!this.mounted) {
      return false
    }
    if (this.iface == null) {
      return false
    }

    if (this.noEvents) {
      return false
    }
    try {
     if (this.getUrl().endsWith("indexBB.html"))
     {
         //  console.log(arg.pubsub.kleur,this.props.pubsub.kleur);
         // console.log("update ifc viewer");
     }
     }
     catch(e)  { }

     if (shallowCompare(this, arg, arg2)==false)
     {
       if (_.isEqual(arg.pubsub, this.props.pubsub))
       {
      // console.log("IFRAME NOT UPDATING geen verschil gevonden");
      
       return false;
       }
     }

    // console.log("should component update "+this.getUrl(),arg,arg2,this.noEvents,this.iface);
    this.updateMe(arg, arg2)
    return false
  }

  registerMe() {
    //console.log("IFRAME register me "+this.getUrl());

    var me = this
    me.iface.me = me
    // console.log("add set loading to iface");
    me.iface.setLoading = function (arg) {
      me.props.setLoading(arg)
    }
    me.iface.setMessage=function(msg)
    {
     //hier norents toaster functie
     if (msg==null)return;
     try
     {
        me.props.sendNotification(msg)
     }
     catch(e){}
     console.log("Toaster msg:",msg)


    }

    me.iface.setUserActionsDisabled = (userActionsDisabled) =>
      me.props.dispatch({
        type: SET_USER_ACTIONS_DISABLED,
        data: userActionsDisabled,
      })

    if (me.iface.widget == null) {
      me.iface.widget = {
        sendEvent: function (e, arg) {
          var pvar = me.props.publishVariable
          if (pvar != null) {
            //  console.log("running iframe onclick event ",e,arg);
            me.onClick(arg)
            me.setState({ selectedUri: arg })
          }
        },
        test: function () {
          console.log('test')
        },
        reExecuteSparql: function () {
          try {
            me.reExecuteSparql()
          } catch (e) {
            console.log(e)
          }
        },
      }
    }

    if (this.mounted) {
      try {
        if (me.iface.afterIntegration != null) {
          //  console.log("after integration called");
          me.iface.afterIntegration()
        }
      } catch (e) {
        console.log(e)
      }
      //console.log("registerMe this.updateMe"+this.getUrl());
      this.updateMe(this.props)
    }
  }
  updateMe = throttle((arg,arg2) => this.updateMe2(arg,arg2), 200)

  updateMe2(arg, arg2) {


   
 //   console.log("really updating ");
    var data = null
    if (arg == null) {
      arg = {}
    }
    try {
      data = arg.data
    } catch (e) {}
    var me = this
    try {
      if (this.props.data == null && data == null && me.firstData != null) {
        // console.log("setbegin sparql results   1");
        var q = me.props.query
        if (this.props.basequery) q = this.props.basequery
        if (arg.executedSparql != null) q = arg.executedSparql
        let qq = null
        if (q != null) {
          qq = parseTextForVariables(q, arg)
        }

        me.iface.setBeginSparqlResults(me.firstData, q, null, null, qq)
        delete me.firstData
      } else {
        if (arg.data != null) {
          if (this.latestData !== arg.data) {
            let q = arg.query
            if (arg.executedSparql != null) q = arg.executedSparql

            let qq = null
            if (q != null) {
              qq = parseTextForVariables(q, arg)
            }
           //  console.log(this.getUrl(),"BB IFRAME SET BEGIN SPARQL results  2",this.props.pubsub,arg.pubsub);
            me.iface.setBeginSparqlResults(arg.data, q, null, null, qq)
            this.latestData = arg.data
          }
        } else {
          if (this.props.data != null) {
            if (this.latestData !== this.prop.data) {
              let q = this.props.query
              if (arg.executedSparql != null) q = arg.executedSparql

              let qq = null
              if (q != null) {
                qq = parseTextForVariables(q, arg)
              }
                 // console.log("BB Iframe 3");
              me.iface.setBeginSparqlResults(this.props.data, q, null, null, qq)
              this.latestData = this.props.data
            }
          } else {
            if (this.props.basequery != null) {
              me = this
              if (this.onlyOnce == null) {
                this.onlyOnce = 'done'
                if (this.props.basequery.length > 10) {
                  //   console.log("iframe geen data maar wel een base query",this.props.basequery);
                  window.top.query(this.props.basequery, function (data) {
                    me.firstData = data
                    me.updateMe(null)
                  })
                }
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }

    //console.log("me iface setPubSub ",this.iface,this.props.pubsub);
    //console.log(arg);
    try {
      this.iface.setPubSub(arg.pubsub)
    } catch (e) {}
    if (arg === {}) {
      arg = this.props
    }
    //console.log("set selection after update",arg);
    this.setSelection(arg)
  }

  setSelection(arg) {
    //console.log("iframe setSelection ",this.getUrl());
    try {
      var pvar = arg.subscribeVariable
      if (pvar != null && arg.pubsub != null) {
        var selectionValue = arg.pubsub[pvar]

        if (
          selectionValue != null &&
          this.state != null &&
          this.state.selection !== selectionValue
        ) {
          if (this.iface != null) {
            // console.log("set Selection iface ",selectionValue);
            this.iface.selectUri(selectionValue)
            this.state.selection = selectionValue //eslint-disable-line react/no-direct-mutation-state
            // this.iface.setBeginSparqlResults(me.props.data,me.props.query);
          } else {
            // console.log("iframe set selection no iface");
          }
        }
      }
    } catch (e) {
      console.log('error met selection ', e)
    }
  }

  reload() {
    this.once = null
    this.iface = null

    this.onlyOnce = null
    window.top.Singleton.getInstance().iframes[this.htmlid] = null
    this.htmlid = null
    //console.log("reload iframe");
    this.setState({ reloadID: Date.now() })
  }

  render() {
    //console.log("rendering "+this.getUrl());
    if (this.once == null) {
     // if (this.props.withinSelectedTab==false) return null;
      this.renderOnce()
      this.once = 'done'
    }
    var height = '100%'

    try {
      if (this.props.height != null) {
        height = this.props.height
      }
    } catch (e) {}

    var reload = 'reload=' + this.state.reloadID // used to reload the whole iframe

    var className = ''
    if (this.props.className) {
      className = this.props.className
    }
    var par = '?y=y'
    var title = ''
    if (this.props.title) {
      title = this.props.title
      par += '&title=' + title
    }
    var createRoot = ''
    if (this.props.createRoot) {
      createRoot = this.props.createRoot
      par += '&createRoot=' + createRoot
    }
    var icon = ''
    if (this.props.icon) {
      icon = this.props.icon
      par += '&icon=' + icon
    }

    var expandLevel = ''
    if (this.props.expandLevel) {
      expandLevel = this.props.expandLevel
      par += '&expandLevel=' + expandLevel
    }
    var direction = ''
    if (this.props.direction) {
      direction = this.props.direction
      par += '&direction=' + direction
    }

    //onderstaande code moeten we eigenlijk vanaf. IFrame objecten kunnen nu via IFace toegang krijgen
    par = ''
    let extra = '?'
    for (let key in this.props) {
      if (key === 'loading') continue
      if (key === 'save') continue
      if (key === 'query') continue
      if (key === 'definition') continue
      if (key === 'children') continue
      if (key === 'pubsub') continue
      if (key === 'basequery') continue
      if (key === 'repoURL') continue
      if (key === 'localRepoURL') continue
      if (key === 'sparqlErrorMessage') continue
      if (key === 'titleMenubar') continue
      if (key === 'widget') continue
      if (key === 'panelstyle') continue
      if (key === 'panelstyle2') continue
      if (key === 'extendquery') continue
      if (key === 'jsAfterSelection') continue
      if (key === 'parameterValues') continue
      if (key === 'data') continue
      if (key === 'withinSelectedTab') continue
      if (key === 'selectguidquery') continue

      let value = this.props[key]

      if (value != null) {
        if (typeof value === 'function') continue
        if (Array.isArray(value)) {
          continue //value=JSON.stringify(value); console.log("submitting array key  to iframe",key);
        }

        try {
          if (value.toString().includes('||')) {
            value = value.toString().replaceAll('||', '@@')
          }
        } catch (e) {}
        try {
          value = value.toString().replaceAll('{{', '!@!@')
        } catch (e) {}
        try {
          value = value.toString().replaceAll('}}', '@!@!')
        } catch (e) {}
        par += extra + key + '=' + value
        extra = '&'
      }
    }

    //console.log(par,this.props);

    let loading = 'lazy'
    if (par === '') reload = '?' + reload
    else reload = '&' + reload

    //
    //  console.log(this.state);
    return (
      <iframe //eslint-disable-line jsx-a11y/iframe-has-title
        id={this.htmlid}
        loading222={loading}
        className={className}
        width="100%"
        height={height}
        frameBorder="0"
       // referrerPolicy="same-origin" 
        scrolling={this.scrolling}
        src={process.env.PUBLIC_URL + this.getUrl() + par + reload}
      />
    )
  }
  renderOnce() {
    var me = this
    this.getUrl() // setting BBIFrame...
  //  console.log('render once ' + this.getUrl())
    try {
      if (!this.props.data || this.props.data.results.bindings.length === 0) {
        if (this.props.renderEmpty) {
          //  console.log("IFRAME GENERATING EMPTY RESPONSE");
          this.firstData = generateEmptyResponse(this.props.query)
        }
      }
    } catch (error) {}

    if (this.htmlid == null) {
      BBIFrame.htmln++
      this.htmlid = 'iframe' + BBIFrame.htmln
      //  console.log(this.htmlid,this);
    }
    if (window.top.Singleton.getInstance().iframes == null) {
      window.top.Singleton.getInstance().iframes = {}
    }

    window.top.Singleton.getInstance().iframes[this.htmlid] = function (arg) {
      me.iface = arg
      me.registerMe()
    }
  }
}
export default BBIFrame
