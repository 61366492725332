class PublishUtils {
  static setTimeStampParameter(widget, debug) {
    if (debug) {
      console.log(
        'timestamp parameter called ',
        widget.props.timestampParameters
      )
    }
    if (widget.props.timestampParameters) {
      var valuesRaw = widget.props.timestampParameters
      var values = valuesRaw.split(',')
      var me = widget
      var value = 'time:' + Date.now()
      if (debug) console.log(values, value)

      if (values.length > 0) {
        if (
          widget.props.pubsub[values[0]] != null &&
          widget.props.pubsub[values[0]] !== value
        ) {
          //console.log(widget);
          setTimeout(function () {
            console.log('resetting parameter 1 ' + values[0], value)
            widget.props.publish(values[0], value)
          }, 25)
        }
        //   data[values[n]]="http://www.buildingbits.nl/reset";
      }
      if (values.length > 1) {
        if (
          widget.props.pubsub[values[1]] != null &&
          widget.props.pubsub[values[1]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 2 ' + values[1])
            me.props.publish(values[1], value)
          }, 50)
        }
      }
      if (values.length > 2) {
        if (
          widget.props.pubsub[values[2]] != null &&
          widget.props.pubsub[values[2]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 3 ' + values[2])
            me.props.publish(values[2], value)
          }, 75)
        }
      }
      if (values.length > 3) {
        if (
          widget.props.pubsub[values[3]] != null &&
          widget.props.pubsub[values[3]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 4 ' + values[3])
            me.props.publish(values[3], value)
          }, 100)
        }
      }
      if (values.length > 4) {
        if (
          widget.props.pubsub[values[4]] != null &&
          widget.props.pubsub[values[4]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 5 ' + values[4])
            me.props.publish(values[4], value)
          }, 125)
        }
      }
      if (values.length > 5) {
        console.log('too many properties to reset')
        alert('too many properties to reset in search box')
      }

      if (debug) {
        console.log('reset parameter called for ', values)
      }
    }
  }

static publish(props,key,value)
{
  var data={   key:value  };

  props.dispatch({
    type: 'PUBLISH',
    data: data
  });
  
}




  static getResetValueDef() {
    return {
      name: 'resetValues',
      type: 'text',
      label:
        'reset this parameter after select event. multiple parameters via comma seperation',
    }
  }

  static getTimestampParametersDef() {
    return {
      name: 'timestampParameters',
      type: 'text',
      label:
        'sets a timestamp after main event. multiple parameters via comma seperation',
    }
  }

  static resetParameter(widget, debug) {
    if (debug) {
      console.log('reset parameter called', widget.props.resetValues)
    }
    if (widget.props.resetValues) {
      var valuesRaw = widget.props.resetValues
      var values = valuesRaw.split(',')
      var me = widget
      var value = 'http://www.buildingbits.nl/reset'

      if (values.length > 0) {
        if (
          widget.props.pubsub[values[0]] != null &&
          widget.props.pubsub[values[0]] !== value
        ) {
          //console.log(widget);
          setTimeout(function () {
          //  console.log('resetting parameter 1 ' + values[0], value)
            widget.props.publish(values[0], value)
          }, 25)
        }
        //   data[values[n]]="http://www.buildingbits.nl/reset";
      }
      if (values.length > 1) {
        if (
          widget.props.pubsub[values[1]] != null &&
          widget.props.pubsub[values[1]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 2 ' + values[1])
            me.props.publish(values[1], value)
          }, 50)
        }
      }
      if (values.length > 2) {
        if (
          widget.props.pubsub[values[2]] != null &&
          widget.props.pubsub[values[2]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 3 ' + values[2])
            me.props.publish(values[2], value)
          }, 75)
        }
      }
      if (values.length > 3) {
        if (
          widget.props.pubsub[values[3]] != null &&
          widget.props.pubsub[values[3]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 4 ' + values[3])
            me.props.publish(values[3], value)
          }, 100)
        }
      }
      if (values.length > 4) {
        if (
          widget.props.pubsub[values[4]] != null &&
          widget.props.pubsub[values[4]] !== value
        ) {
          setTimeout(function () {
            console.log('resetting parameter 5 ' + values[4])
            me.props.publish(values[4], value)
          }, 125)
        }
      }
      if (values.length > 5) {
        console.log('too many properties to reset')
        alert('too many properties to reset in search box')
      }

      if (debug) {
        console.log('reset parameter called for ', values)
      }
    }
  }

  static processStringForParameters(widget, s) {
    
    let query = s
    if (!query) {
      return
    }
    //const regex = /{{\S*}}/g;
    const regex = /{{[A-Za-z0-9|:./]*}}/g
    let subscribeProps = query.match(regex)

    if (subscribeProps == null) return s
    subscribeProps = subscribeProps.map((f) => f.replace(/[{}]/g, ''))
    subscribeProps = [...new Set(subscribeProps)] // remove duplicates

    for (var i = 0; i < subscribeProps.length; i++) {
      let subscribeProp = subscribeProps[i]
      let subscribeValue = widget.props.pubsub[subscribeProp]
      //console.log(query,subscribeProps,subscribeValue,widget.props);

      if (subscribeValue == null) {
        // console.log("found empty property or composed properties",subscribeProp);
      }

      if (subscribeProp.includes('||')) {
        let subscribeProp2 = subscribeProp.split('||')[0]
        subscribeValue = widget.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }

        //  console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          var prop = subscribeProp.split('||')[1].split('||')[0].split('::')[0]
          subscribeValue = widget.props.pubsub[prop]
          if (subscribeValue === 'http://www.buildingbits.nl/reset') {
            subscribeValue = null
          }
        }
      }

      if (subscribeProp.includes('::') && subscribeValue == null) {
        let subscribeProp2 = subscribeProp.split('::')[0]
        subscribeValue = widget.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }
        // console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          subscribeValue = subscribeProp.split('::')[1]
        }
        //  console.log(subscribeProp,subscribeValue);
      }

      subscribeProp = subscribeProp.replaceAll('||', '\\|\\|')
      let regex2 = new RegExp(`{{${subscribeProp}}}`, 'g')
      // var o={q:query};

      //console.log("changing query ",query,regex2,subscribeValue);
      try {
        if (subscribeValue == null) subscribeValue = ''
        query = query.replace(regex2, subscribeValue)
        // console.log("results ",query);
      } catch (e) {
        console.log(
          'ERROR ',
          query,
          subscribeValue,
          subscribeProps,
          widget.props.pubsub[subscribeProp]
        )
      }
      // o.q2=query;
      //console.log(o,subscribeProp);
    }
    return query
  }

  static emptyDataParameterChange(widget, arg, arg2) {
    if (widget.props.resetDataProps) {
      if (arg.data != null) {
        console.log(widget.props.pubsub.button, arg.pubsub.button)
        console.log(widget, arg, arg2)
      }
    }
  }

  static getEmptyDataParameterChange() {
    return {
      name: 'resetDataProps',
      type: 'text',
      label:
        'reset data value when this parameter changes. multiple parameters via comma seperation',
    }
  }


  static getMultiplePropValueAsSingleValue(props,values)
  {
    let valueString = ''
      let komma = ''
      let pre = '<'
      let end = '>'
      let kommaT = ','
      if (props.multipleselectparametertype === 'csvstring') {
        pre = '"'
        end = pre
      }
      if (props.multipleselectparametertype === 'valuestring') {
        pre = '("'
        end = '") '
        kommaT = ''
      }
      if (props.multipleselectparametertype === 'valueuri') {
        pre = '(<'
        end = '>) '
        kommaT = ''
      }

      for (let n in values) {
        if (values[n]) {
          valueString += komma + pre + n + end
          komma = kommaT
        }
      }
      //  console.log(valueString);
      if (valueString === '') valueString = '<http://www.buildingbits.nl/reset>';
      return valueString;
  }


  static getMultipleValueFormatOptions()
  {
    return  {
      name: 'multipleselectparametertype',
      type: 'select',
      options: [
        { label: 'csv as uri', value: 'csvuri' },
        { label: 'csv as string with "', value: 'csvstring' },
        { label: 'sparql value string with "', value: 'valuestring' },
        { label: 'sparql value uri)', value: 'valueuri' },
      ],
      label:
        'for Multiple selection only: how to set the multivalue parameters',
    }
  }

}

export default PublishUtils
