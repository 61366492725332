import React, { Component } from 'react'

import styles from './BBHTML.module.css'

export default class BBHTML extends Component {
  state = {}

  static getDescription() {
    return {
      name: 'BBHTML',
      component: BBHTML,
      label: 'BBHTML',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter {{parameters}} to change html ',
        },
        {
          name: 'html',
          type: 'text',
          label: 'html ',
        },
        {
          name: 'htmlgeenresultaat',
          type: 'text',
          label: 'html bij 0 resultaten',
        },
        {
          name: 'cls',
          type: 'text',
          label: 'class ',
        },
      ],
    }
  }

  render() {
    try
    {
    if (!this.props.html && !this.props.htmlgeenresultaat) {
      return null
    }
    let html = this.props.html
    var html2 = ''
    let data = this.props.data
    if (data != null && html != null) {
      if (
        data.results != null &&
        data.results.bindings != null &&
        data.results.bindings.length > 0
      ) {
        var object = data.results.bindings[0]
        var html3 = html
        for (var key in object) {
          try {
            var value = object[key].value
            html3 = html3.replace('{{' + key + '}}', value)
          } catch (e) {}
        }
        html2 += html3
      }

      if (
        this.props.htmlgeenresultaat != null &&
        data.results.bindings.length === 0
      ) {
        html2 = this.props.htmlgeenresultaat
      }
    } else {
      html2 = html
    }

    html2 = html2.replace(/.*{{.*}}.*/, '')

    let cls = this.props.cls
    if (styles[cls] != null) cls = styles[cls]
    return <div className={cls} dangerouslySetInnerHTML={{ __html: html2 }} />
  }
  catch(e)
  {
    console.log("mannaged ",e);
  }
  return null;
  }
}
