import BBIFrame from './BBIFrame'
import PublishUtils from './../../../helpers/PublishUtils'

export default class BBVis extends BBIFrame {
  constructor() {
    super()

    this.scrolling = 'false'
    this.noEvents = false
  }

  getUrl() {
    // console.log("BB Table should getURL");

    return '/bb/w2ui/visgraph.html'
  }

  static getDescription() {
    return {
      name: 'BBVis',
      component: BBVis,
      label: 'graph vis',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the graph. Use node nodeLabel edge edgeLabel object objectLabel. optional hier (true false) for hierarchical rep. shape/shapeO (e.g circle) for node shape. objectType & nodeType voor kleur/groepering ',
        },
        {
          name: 'extendquery',
          type: 'yasgui',
          label: '  {{uri}} and Use  edge edgeLabel object objectLabel',
        },
        {
          name: 'edgelabels',
          type: 'boolean',
          label: 'show edge labels using edgeLabel',
        },
        {
          name: 'useLocalNames',
          type: 'boolean',
          label: 'use local names for labels',
        },

        {
          name: 'legend',
          type: 'boolean',
          label: 'show legend based upon parameters objectType and nodeType',
        },

        {
          name: 'publishVariable',
          type: 'text',
          label:
            'Variabele in which an item URI is published, when a feature in the map is clicked.',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published uri variabele on which to listen to define selection.',
        },
        {
          name: 'direction',
          type: 'select',
          label: 'direction layout',
          options: [
            {
              label: 'Vertical starting from top',
              value: 'UD',
            }, //UD, DU, LR, RL
            {
              label: 'Vertical starting from bottom',
              value: 'DU',
            }, //UD, DU, LR, RL
            {
              label: 'Horizontal starting from left',
              value: 'LR',
            }, //UD, DU, LR, RL
            {
              label: 'Horizontal starting from right',
              value: 'RL',
            },
          ],
        },
        {
          name: 'edgetype',
          type: 'select',
          label: 'edge type',
          options: [
            { label: 'none', value: null },
            {
              label: 'to',
              value: 'to',
            }, //UD, DU, LR, RL
            {
              label: 'from',
              value: 'from',
            }, //UD, DU, LR, RL
            {
              label: 'middel',
              value: 'middle',
            }, //UD, DU, LR, RL
            {
              label: 'to and from',
              value: 'to;from',
            },
          ],
        },
        {
          name: 'hierarchical',
          type: 'boolean',
          label: 'hierarchical',
        },

        {
          name: 'dragNodes',
          type: 'boolean',
          label: 'drag nodes',
        },
        {
          name: 'editable',
          type: 'boolean',
          label: 'editable',
        },
        PublishUtils.getResetValueDef(),
        {
          name: 'className',
          type: 'text',
          label: 'iframe classNames',
        },
      ],
    }
  }
}
