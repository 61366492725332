
import { lazy } from 'react';
import PublishUtils from '../../../../helpers/PublishUtils';
//import {PublishUtils} from PublishUtils;


export default class BBDFormDescriptor  {
 
  static getDescription() {
    return {
      name: 'BBDForm',
      component: lazy(() =>  import('./BBDForm')),
      label: 'BBDForm',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de form',
        },
        {
          name: 'class',
          type: 'text',
          label: 'uri van de owl/shacl classe',
        },
        {
          name: 'query',
          type: 'yasgui',
          label:
            'to fill the form. Use graph,title,uri, propUri, label,value, type (xsd,select), optionLabel optionValue ?required regex, multiple,objectOfCls, disabled,hidden ',
        },
        {
          name: 'cancelbutton',
          type: 'boolean',
          label: ' show  cancel button.',
        },
        {
          name: 'readonly',
          type: 'boolean',
          label: ' ready only, no buttons',
        },
       
        {
          name: 'disabled',
          type: 'boolean',
          label: 'disabled',
        },
        {
          name: 'emptyText',
          type: 'text',
          label: 'text by empty sparql results',
        },
        {
          name: 'submit',
          type: 'select',
          label: 'submit handling ',
          options: [
            { label: 'kies', value: 'kies' },
            { label: 'form command', value: 'formCommand' },
            { label: 'internal parameters', value: 'internalparameters' },
            { label: 'javascript', value: 'javascript' },
          ],
        },
        
        PublishUtils.getTimestampParametersDef(),
        {
          name: 'failureMessage',
          type: 'text',
          label: 'failure message',
        },
        {
          name: 'closePopup',
          type: 'boolean',
          label: 'close popup after succesful submit',
        },
      ],
    }
  }

  
}
