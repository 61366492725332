import { combineReducers } from 'redux'
import event from './event'
import project from './project'
import projects from './projects'
import users from './users'
import pubsub from './pubsub'
import me from './me'

export default combineReducers({
  event,
  project,
  projects,
  users,
  pubsub,
  me,
})
