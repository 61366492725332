import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import styles from './Viewer.module.css'
import { Button, Input, FormGroup, Label } from 'reactstrap'
import Dialog from './Dialog'
import { Link } from 'react-router-dom'
import axios from 'axios'
import gear from '../images/gear.svg'
import preview from '../images/preview.svg'
import Header from './Header'
import Footer from './Footer'
import Notification from './Notification'
import BackButton from './BackButton'
import Area from './Area'
import Spinner from './Spinner'
import defaultConfig from './defaultConfig.json'
import { Helmet } from 'react-helmet'
import LoginHelper from '../helpers/LoginHelper'
import { Dialog as InfoDialog, MenuItem, Select } from '@material-ui/core'
import { inflateLayout, LAYOUT_NAME } from '../helpers/layout'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AutoHyperlink } from './AutoHyperlink'
import { SET_LAYOUT_NAME } from '../redux/project'
import { getAbsoluteRepoUrl, getCgiUrl } from '../helpers/url'

class Viewer extends Component {
  state = {
    popupInfoVisible: false,
    dialogVisible: false,
    notification: {},
    loading: true,
    showJSONEditor: false,
  }

  onJsonEditClick = () => {
    this.setState({ showJSONEditor: true })
  }

  getUrl(data) {
    //console.log("get URL ",data);
    try {
      let url = '/api/sparql/' + data.repoURL
      if (data.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = data.repoURL
      }
      var modus = false
      if (window.location.host === 'localhost:3000') {
        modus = true
      }
      //  var modus=false; //debug

      if (data.localRepoURL != null && data.localRepoURL !== '' && modus) {
        url = data.localRepoURL
      }

      return url
    } catch (e) {
      console.log('error creating url ', e)
    }
    return null
  }

  isLoggedIn = (ilUrl) => {
    // console.log("is logged in ",ilUrl);

    //var ilUrl="http://localhost:8080/BloodBee/cgi/isloggedin";
    axios({
      method: 'get',
      withCredentials: true,
      url: ilUrl,
      //credentials: 'include'
    }).then(({ data }) => {
      console.log('is logged in:', data)
    })
  }

  requiresBBLogin = (data, rv) => {
    const repoUrl = getCgiUrl('sparql', data.repoURL, data.localRepoURL)
    if (repoUrl == null) return

    var parameters = data.bbsparqlendpoint
    let repo = new URLSearchParams(parameters).get('repo')
    let repo2 = new URLSearchParams(parameters).get('repo2')
    if (repo2 == null) repo2 = ''

    const isLoggedInUrl = getCgiUrl('isloggedin', data.repoURL, data.localRepoURL)
    axios.defaults.withCredentials = true
    axios.defaults.credentials = 'include' // deze moet wel aanstaan
    //console.log(repo);//extract repo parameter
    var data2=data;
    axios({
      method: 'get',
      url: isLoggedInUrl,
      withCredentials: true,
      credentials: 'include',
    }).then(
      ({ data }) => {
        //  console.log("raw is logged in ",data);

        try {
          if (data.loggedIn === 'TRUE') {
            if (repo != null && data.repo != null) {
              if (repo.toLowerCase() === data.repo.toLowerCase() || repo2.toLowerCase() === data.repo.toLowerCase()) {
                console.log('remotely logged with repo check in so starting application')

                if (data.parameters!=null)
                {
                
                    document.userParameters=data.parameters;
                
                }


                rv.call(this, this)
              } else {
                //  console.log("wrong repo");
                alert('wrong repo. This account is not valid for this application.')
                var id = encodeURIComponent(btoa('?repoUrl=' + repoUrl + '&' + parameters))
                this.props.history.push('/loginbb?id=' + id, this.props.location.pathname)

               if (false) {
                  //logoff and restart?
                  LoginHelper.logoff(repoUrl);
                  //window.top.location.reload();
                }
              }
            } else {
              //  console.log(' logged in so starting application')
              rv.call(this, this)
            }
          } else {
            //  console.log("not logged in so going to login page ");

            let psw = new URLSearchParams(parameters).get('psw')
            let login = new URLSearchParams(parameters).get('login')
            if (psw != null && login != null) {
              // console.log('default login found')
              LoginHelper.login(repoUrl, repo, login, psw, rv)

              return
            }

            try {
              id = encodeURIComponent(btoa('?repoUrl=' + repoUrl + '&' + parameters))
              this.props.history.push('/loginbb?id=' + id, this.props.location.pathname)
            } catch (error) {
              //console.log(data.prejs);
              console.log(error)
            }
          }
        } catch (e) {
          console.log(e)
        }
      },
      ({ error }) => {
        //console.log(error);
        var id = encodeURIComponent(btoa('?repoUrl=' + repoUrl + '&' + parameters))
        //   console.log("going to bb login pagae");
        this.props.history.push('/loginbb?id=' + id, this.props.location.pathname)

        rv.call(this, this)
      }
    )

    return false
  }

  componentDidMount() {
    const { projectPath, viewerPath } = getViewProps(this.props)

    let viewer = this.props.project.viewers.find((v) => v.path === viewerPath)
    if (viewer && viewer.loaded) {
      return this.loadViewerIntoState(viewer)
    }

    let url = `/api/projects/${projectPath}/viewers/${viewerPath}`
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
    }
    if (window.CONNECTED_APPS_STATIC) {
      headers = []
      url = `./config-v2.json?t=${Date.now()}`
    }

    // console.log(' url to json is ' + url)

    let me = this
    let axiosO={
      method: 'get',
      url      
    };
    if (headers.length>0)
    {
      axiosO.headers=headers;
    }
    else
    {

      axiosO.headers={'Content-Type':'application/json'};

    }
    
    //console.log(axiosO);

    axios(axiosO)
      .then(({ data }) => {
      // console.log('json ', data)
        if (data != null) {
          if (data.prejs != null) {
            try {
              eval(data.prejs) // eslint-disable-line no-eval
            } catch (error2) {
              console.log(error2, data.prejs)
            }
          }

          if (data.bbsparqlendpoint != null) {
            if (data.bbsparqlendpoint === '') {
              data.bbsparqlendpoint = null
            }
          }

          if (data.bbsparqlendpoint != null) {
            //console.log('bb sparql endpoint found')
            let shouldLogin = true

            if (data.localRepoURL != null && data.localRepoURL !== '' && window.location.host === 'localhost:3000') {
              // console.log("should we login? perhaps use localRepoUrl? is it port :7200?",data.localRepoURL);
              // shouldLogin=false;
              if (data.localRepoURL.includes(':7200')) {
                shouldLogin = false
              }
            }
            if (shouldLogin) {
              //  console.log(' we need to login')

              if (
                me.requiresBBLogin(data, function () {
                  me.loadViewerIntoState(data)
                  me.props.dispatch({ type: 'FETCH_VIEWER_SUCCESS', data })
                })
              );
              return
            }
          }

          this.loadViewerIntoState(data)
          this.props.dispatch({ type: 'FETCH_VIEWER_SUCCESS', data })
        }
      })
      .catch((error) => {
        if (!error.response) throw error

        console.log(error)
        if (error.response?.status === 401) this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)

        if (error.response?.status === 404)
          this.setState({
            viewer: inflateLayout({
              ...defaultConfig,
              path: viewerPath,
              new: true,
            }),
            loading: false,
          })
      })
  }

  componentDidUpdate() {
    const viewer = this?.state?.viewer
    if (viewer == null) return

    if (this.state.viewer.update60Enabled) {
      // console.log("we shoud update 60 ",this.state);
      if (this.state.update60Running) {
        // console.log("but is already running");
      } else {
        // console.log("starting update 60");
        this.changingProps()
      }
    } else {
      // console.log("update 60 should not be running")
      if (this.state.update60Running) {
        this.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60Running: false,
          },
        })
        //  console.log("disabling update 60")
      } else {
        // console.log("update60 is not running");
      }
    }

  }


 myPublish(par,value)
 {
  var data ={};
  data[par]=value;
  this.props.dispatch({
    type: 'PUBLISH',
    data: data
  })
 }




 handleDefaultParameterValues(props) {
   // console.log("handle default parameters");
  try {
    //cgi parameter
 //   console.log(this.props);
    let cgi = props.repoURL.replace('sparql', '')
    this.myPublish('cgi', cgi)
    let params = props.parameterValues
    if (params != null) {
     // console.log("handle default parameter values",params);
        params = JSON.parse(params)

        for (let n in params) {
       //   console.log("publishing default parameters ",n,params[n]);
          this.myPublish(n, params[n])
         
        }
     
      }
  } catch (e) {
    console.log('error (1) parsing default values json (managed)',e);
    
  }



  if (props.parseUrlProperties)
  {
   // console.log("handle url properties if available");
    var me=this;
   
    try
    {
      let search=window.top.window.location.search;
      let u=new URLSearchParams(search);
      let entries = u.entries();
      for(const entry of entries) {
   
       var key2 = entry[0];
       var value2=entry[1];
     
        this.myPublish(entry[0],entry[1]);
    
      }

 //     console.log("end url properties ");
    }
    catch(e){console.log(e);}
  
    
  }

  if (document.userParameters)
  {
   // console.log("user login properties",document.userParameters);
      try
      {
           for (var key in document.userParameters)
           {
            var value=document.userParameters[key];
            this.myPublish(key,value);
           }
      }
      catch(e)
      {}
  }


}


  loadViewerIntoState(viewer) {
  //  console.log("log viewer into state");
    this.setState({
      viewer: inflateLayout(viewer),
      loading: false,
      popupInfoVisible: viewer.popupInfoAtStart,
    })
    this.props.dispatch({ type: SET_LAYOUT_NAME, data: viewer.layoutName })

    this.handleDefaultParameterValues(viewer);
     // console.log(viewer,this);

  }


  updateViewer(partialViewer) {
    this.setState({ viewer: { ...this.state.viewer, ...partialViewer } })
  }

  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  }
  changingProps() {
    if (this.state.update60Running) return

    // console.log("setting first update60 value");

    this.props.dispatch({
      type: 'PUBLISH',
      data: {
        update60: 'start',
        update60Running: true,
      },
    })

    this.changingProps60(true)
  }

  changingProps60(b) {
    if (b || this.state.viewer.update60Enabled) {
      var me = this
      if (me.loop60) return
      me.loop60 = true
      setTimeout(function () {
        // console.log("changing update60 value");
        let id = me.uuidv4()
        me.props.dispatch({
          type: 'PUBLISH',
          data: {
            update60: id,
          },
        })
        console.log('changing 60')
        me.loop60 = false
        me.changingProps60(false)
      }, 60000)
    }
  }








  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  onChange = (config) => {
    let viewer = { ...this.state.viewer, mainArea: config }
    this.setState({ viewer })
  }

  selectLayout = (layoutName) => {
    let viewer = inflateLayout({ ...this.state.viewer, layoutName })
    this.setState({ viewer })
    this.props.dispatch({ type: SET_LAYOUT_NAME, data: layoutName })
  }

  save = () => {
    let viewer = this.state.viewer
    delete viewer.project

    const { projectPath, viewerPath } = getViewProps(this.props)

    let url = `/api/projects/${projectPath}/viewers` + (viewer.new ? '' : `/${viewerPath}`)
    axios({
      method: viewer.new ? 'post' : 'put',
      url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('kvk-vb-token')}`,
      },
      data: viewer,
    })
      .then(({ data }) => {
        this.setState({ notification: { text: 'saved', type: 'success' } })
        this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)

        let viewer = inflateLayout({ ...data, new: false })
        this.setState({ viewer })
        this.props.dispatch({ type: 'SAVE_VIEWER_SUCCESS', data })
        let s = data
        delete s.id
        delete s.path
        delete s.project
        delete s.user
        delete s.createdAt
        // console.log(JSON.stringify(s));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.props.history.push('/login', `projects/${projectPath}/${viewerPath}`)
        } else {
          this.setState({
            notification: {
              text: 'not saved: ' + error.response.data,
              type: 'failure',
            },
          })
          this.timer = setTimeout(() => this.setState({ notification: {} }), 3000)
        }
      })
  }

  onDialogOkClick = () => {
    this.setState({ dialogVisible: false })
    this.save()
  }

  onHeaderClick = (e) => {
    if (getViewProps(this.props).mode === 'edit') {
      this.setState({ dialogVisible: true })
    }
  }

  jsonOKClick = () => {
    this.setState({ showJSONEditor: false })
  }

  render() {
    // console.log("viewer render function",this);
    if (this == null) return <Spinner />
    //  if (true){ var id="halalo"; this.props.history.push('/loginbb?id='+id, this.props.location.pathname); return null;}
    if (this.state == null) return <Spinner />
    if (this.state.loading) return <Spinner />
    let { isAuthenticated, role } = this.props.me

    let viewer = this.state.viewer
    if (!viewer) return null
    var me = this
    document.st = function () {
      viewer.notShowTop = false
      me.setState({ notShowTop: false })
    }
    document.preview = function () {
      getViewProps(this.props).mode = 'preview'
      me.setState({ mode: 'preview' })
    }
    document.edit = function () {
      getViewProps(this.props).mode = 'edit'
      me.setState({ mode: 'edit' })
    }

    let notification = this.state.notification
    const { mode, projectPath, viewerPath } = getViewProps(this.props)

    let showBottom = viewer.notShowBottom
    if (showBottom == null) {
      showBottom = true
    } else {
      showBottom = !showBottom
    }

    let showTop = viewer.notShowTop
    if (showTop == null) {
      showTop = true
    } else {
      showTop = !showTop
    }

    let css = viewer.css
    if (css) {
      try {
        css = JSON.parse(css)
      } catch (error) {
        css = {}
      }
    } else {
      css = {}
    }

   
    /*if (this.state.showJSONEditor)
    {
              return (<JsonEditor></JsonEditor>)
    }
    else
    */
        
    return (
      <div className={styles.wrapper} style={css}>
        <div className={styles.mainContainer}>

          <Helmet>
            <title>{viewer.title}</title>
            {viewer.project && (
              <link
                rel="icon"
                type="image/png"
                href={`${window.CONNECTED_APPS_STATIC ? '.' : ''}/favicon-${viewer.project.path}.png`}
                sizes="16x16"
              />
            )}
          </Helmet>
          <Notification messageType={notification.type} text={notification.text} />

          {!showTop && isAuthenticated && (
            <>
              {mode === 'edit' && (
                <img
                  onClick={this.onHeaderClick}
                  src={gear}
                  alt="gear"
                  style={{ position: 'absolute', cursor: 'pointer' }}
                />
              )}
              <Link
                to={`/projects/${projectPath}/${viewerPath}${mode === 'edit' ? '' : '/edit'}`}
                style={{ textDecoration: 'none' }}
              >
                <img src={gear} alt="gear" style={{ position: 'absolute', right: 0, top: 0, cursor: 'pointer' }} />
              </Link>
            </>
          )}

          {showTop && (
            <Fragment>
              {!window.CONNECTED_APPS_STATIC && <BackButton to={`/projects/${projectPath}`} />}
              {isAuthenticated && role !== 'viewer' && (
                <div className={styles.editButton}>
                  {mode === 'edit' ? (
                    <Link
                      to={`/projects/${projectPath}/${viewerPath}`}
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      <img src={preview} alt="preview" style={{ width: '22px' }} />
                    </Link>
                  ) : (
                    <Link to={`/projects/${projectPath}/${viewerPath}/edit`} style={{ textDecoration: 'none' }}>
                      <img src={gear} alt="gear" style={{ width: '19px', opacity: '0.5' }} />
                    </Link>
                  )}
                </div>
              )}

              <Header
                title={viewer.title}
                subtitle={viewer.subtitle}
                repoURL={viewer.repoURL}
                localRepoURL={viewer.localRepoURL}
                bbsparqlendpoint={viewer.bbsparqlendpoint}
                pageWidth={viewer.pageWidth}
                parseUrlProperties={viewer.parseUrlProperties}
                noLoginButton
                logoURL={viewer.logoURL}
                onInfoClick={viewer.infoMessage ? () => this.setState({ popupInfoVisible: true }) : null}
                onSettingsClick={mode === 'edit' ? this.onHeaderClick : null}
              />
            </Fragment>
          )}

          <div className={styles.main} style={{ maxWidth: viewer.pageWidth }}>
            <div className={styles.areaContainer}>
              <Area
                config={viewer.mainArea}
                onChange={this.onChange}
                mode={mode}
                repoURL={viewer.repoURL}
                localRepoURL={viewer.localRepoURL}
                parseUrlProperties={viewer.parseUrlProperties}
                sparqlErrorMessage={viewer.sparqlErrorMessage}
                parameterValues={viewer.parameterValues}
                save={this.save}
              />
            </div>
          </div>

          {this.state.viewer.layout?.showFooter && (
            <Footer onClick={mode === 'edit' ? this.onHeaderClick : null} config={viewer.footer} />
          )}

          <InfoDialog
            style={{ zIndex: 1337 }}
            maxWidth="xl"
            open={Boolean(this.state.popupInfoVisible && viewer.infoMessage)}
            onClose={() => this.setState({ popupInfoVisible: false })}
          >
            <div className={styles.infoDialogueContainer}>
              <AutoHyperlink text={viewer.infoMessage} />
            </div>
          </InfoDialog>

          {this.state.dialogVisible && (
            <Dialog>
              <FormGroup>
                <Label>Layout</Label>
                <Select
                  className="dark-form-control form-control white-font-override"
                  onChange={(e) => this.selectLayout(e.target.value)}
                  value={this.state.viewer.layoutName || ''}
                >
                  <MenuItem value={LAYOUT_NAME.CUSTOM}>Custom</MenuItem>
                  <MenuItem value={LAYOUT_NAME.ORIGINAL}>Original</MenuItem>
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>Title</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.title || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ title: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Subtitle</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.subtitle || ''}
                  type="text"
                  onChange={(e) => this.updateViewer({ subtitle: e.target.value })}
                />
              </FormGroup>

              <FormGroup>
                <Label>Info message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.infoMessage || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, infoMessage: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.popupInfoAtStart ?? false}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          popupInfoAtStart: e.target.checked,
                        },
                      })
                    }
                  />
                  show popup info at start
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>pre app javascript</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.prejs || ''}
                  type="javascript"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, prejs: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>bb repo and app parameters</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.bbsparqlendpoint || ''}
                  type="text"
                  onChange={(e) => {
                    this.setState({
                      viewer: { ...viewer, bbsparqlendpoint: e.target.value },
                    })
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Sparql endpoint</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.repoURL || 'https://connectedapps.nl/linkeddata/test'}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, repoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>local sparql endpoint (http://localhost:7200/repositories/test)</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.localRepoURL}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, localRepoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>sparql endpoint error message</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.sparqlErrorMessage || '"errors connecting to the server"'}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: {
                        ...viewer,
                        sparqlErrorMessage: e.target.value,
                      },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>Logo URL</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.logoURL || ''}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, logoURL: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Global CSS in JSON formaat, bijvoorbeeld: {'{"color":"red"}'}</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.css || '{height:"100%"}'}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, css: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>Pagina breedte</Label>
                <Input
                  className="dark-form-control"
                  value={viewer.pageWidth || '1120px'}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, pageWidth: e.target.value },
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label>default parameter values </Label>
                <Input
                  className="dark-form-control"
                  value={viewer.parameterValues || '{}'}
                  type="text"
                  onChange={(e) =>
                    this.setState({
                      viewer: { ...viewer, parameterValues: e.target.value },
                    })
                  }
                />
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.update60Enabled}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          update60Enabled: e.target.checked,
                        },
                      })
                    }
                  />
                  enable update60 parameter refresh at 60s interval
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.parseUrlProperties}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          parseUrlProperties: e.target.checked,
                        },
                      })
                    }
                  />
                  parse url parameters as property values
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowTop}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, notShowTop: e.target.checked },
                      })
                    }
                  />{' '}
                  do not show top
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.notShowBottom}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: {
                          ...viewer,
                          notShowBottom: e.target.checked,
                        },
                      })
                    }
                  />
                  do not show bottom
                </Label>
              </FormGroup>

              <FormGroup>
                <Label>Config in JSON formaat</Label>
                <Input
                  className="dark-form-control"
                  value={JSON.stringify(viewer) || ''}
                  type="text"
                  onChange={(e) => {
                    let config = JSON.parse(e.target.value)

                    if (config != null) {
                      var old = this.state.viewer
                      config.id = old.id
                      config.user = old.user
                      config.path = old.path
                      this.setState({ viewer: config })
                    }
                  }}
                />
                <Button color="primary" style={{ minWidth: '120px', marginTop: '12px' }} onClick={this.onJsonEditClick}>
                  edit
                </Button>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    checked={viewer.public}
                    className={styles.settingsCheckbox}
                    type="checkbox"
                    onChange={(e) =>
                      this.setState({
                        viewer: { ...viewer, public: e.target.checked },
                      })
                    }
                  />
                  publiek
                </Label>
              </FormGroup>
              <div style={{ textAlign: 'center' }}>
                <Button color="primary" style={{ minWidth: '120px', marginTop: '12px' }} onClick={this.onDialogOkClick}>
                  OK
                </Button>
              </div>
            </Dialog>
          )}
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    )
  }
}

function getViewProps(props) {
  if (window.CONNECTED_APPS_STATIC)
    return {
      mode: 'preview',
      projectPath: window.PROJECT,
      viewerPath: window.VIEWER,
    }

  return {
    mode: props.match.params.mode,
    projectPath: props.match.params.projectPath,
    viewerPath: props.match.params.viewerPath,
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  me: state.me,
})

export default connect(mapStateToProps)(Viewer)
