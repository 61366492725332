import { lazy } from 'react';

import PublishUtils from './../../helpers/PublishUtils'

export default class TableDescriptor {
    static getDescription() {
        return {
          name: 'Table',
          component: lazy(() =>  import('./Table')), 
          label: 'Table widget',
          variables: [
            {
              name: 'query',
              type: 'yasgui',
              label:
                'Query to fill table. If table item should be a link, than the you should add a property with the same name with suffix "URI", eg: ?object ?objectURI.',
            },
            {
              name: 'publishVariable',
              type: 'text',
              label:
                'Variabele in which item URI is published, when a link in the table is clicked.',
            },
            {
              name: 'highlightVariable',
              type: 'text',
              label: 'highlight table row with this uri',
            },
            {
              name: 'ashtml',
              type: 'boolean',
              label: 'tabel waardes als html',
            },
            {
              name: 'title',
              type: 'text',
              label: 'Titel boven de tabel',
            },
            {
              name: 'renderEmpty',
              type: 'boolean',
              label: 'render an empty table',
            },
            {
              name: 'zeroResult',
              type: 'text',
              label:
                'text to display when sparql results is valid but has zero results',
            },
            {
              name: 'aggregateResultsByFirstParameter',
              type: 'boolean',
              label: 'aggregate sparql results by the first parameter',
            },
            {
              name: 'replaceHeaderJSON',
              type: 'text',
              label:
                'Key value JSON that replaces parameter headers. {"label":"naam (volledig)"}',
            },
            {
              name: 'hasNumberedRows',
              type: 'boolean',
              label: 'show row numbers',
            },
            {
              name: 'centerContent',
              type: 'boolean',
              label: 'center values in the tabel',
            },
            PublishUtils.getResetValueDef(),
            {
                name: 'deletebuttonrule',
                type: 'text',
                label: 'uri for rule when pushing delete icon',
              },
              PublishUtils.getTimestampParametersDef(),
          ],
        }
      }
    
}