import './bbutil'
import BBIFrame from './BBIFrame'

class BBYasguiNotebook extends BBIFrame {
  getUrl() {
    BBIFrame.scrolling = 'yes'

    return '/bb/bbyasguinotebook.html'
    //  return "/bb/bbyasgui.html";
  }
}

export default BBYasguiNotebook
