import { Component } from 'react'
import styles from './List.module.css'

import { Button } from 'reactstrap'
import { restructureResponse } from '../../helpers/sparql'
import PublishUtils from './../../helpers/PublishUtils'

class List extends Component {
  state = { checklist: {} }

  handleOnChange = (e) => {
    // setIsChecked(!isChecked);
    // console.log(e,e.target.value,e.target.checked);
    let checklist = this.state.checklist
    checklist[e.target.value] = e.target.checked

    this.setState({ checklist: checklist })
    let publishVariable = this.props.publishVariable
    if (publishVariable != null) {

      var valueString=PublishUtils.getMultiplePropValueAsSingleValue(this.props,checklist);

      this.props.publish(publishVariable, valueString)
      //PublishUtils.resetParameter(this);
    }
  }

  static getDescription() {
    return {
      name: 'List',
      component: List,
      label: 'List widget',
      variables: [
        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de lijst',
        },
        {
          name: 'query',
          type: 'yasgui',
          label: 'first variabele for the labels ',
        },
        {
          name: 'publishVariable',
          type: 'text',
          label: 'sparql parameter name for publishing select event result',
        },
        {
          name: 'subscribeVariable',
          type: 'text',
          label:
            'Published variabele on which to listen to to define list item selection.',
        },
        {
          name: 'multipleselect',
          type: 'boolean',
          label:
            'allow multiple selections. parameters are filled as comma separated values',
        },
       PublishUtils.getMultipleValueFormatOptions(),
        PublishUtils.getResetValueDef(),
        /*,
    {
      name: 'clearParameter',
      type: 'text',
      label: 'clears widget when parameter changes'
    }*/
      ],
    }
  }

  //state= {clearList:false};
  clearList = false

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps == null) {
      return false
    }
    if (this.props == null) {
      return false
    }
    if (this.props.data == null) {
      return true
    }

    if (nextProps.data !== this.props.data) {
      // let key = this.props.data.head.vars[0]
      // let label = this.props.data.head.vars[0]
      // if (this.props.data.head.vars.length > 1) {
      //   key = this.props.data.head.vars[1]
      // }

      // let items = restructureResponse(this.props.data)
      this.setState({ checklist: {} })
      let publishVariable = this.props.publishVariable
      if (publishVariable != null) {
        this.props.publish(publishVariable, null)
        PublishUtils.resetParameter(this)
      }

      this.setState({ checklist: {} })

      return true
    }

    return true
  }

  renderMultiple() {
    let key = this.props.data.head.vars[0]
    let label = this.props.data.head.vars[0]
    if (this.props.data.head.vars.length > 1) {
      key = this.props.data.head.vars[1]
    }

    // let publishVariable = this.props.publishVariable

    let items = restructureResponse(this.props.data)
    //console.log(key,items);
    let checklist = this.state.checklist
    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <ul className={styles.main}>
          {items.map((item, index) => {
            let checkboxvalue = item[key]
            let check = checklist[checkboxvalue] ?? false

            return (
              <div key={index}>
                <input
                  value={item[key]}
                  type="checkbox"
                  checked={check}
                  onChange={this.handleOnChange}
                  disabled={
                    this.props.syncWithUserActionsDisabled &&
                    this.props.userActionsDisabled
                  }
                ></input>
                <span> {item[label]}</span>
              </div>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    if (!this.props.data) return null
    if (this.props.multipleselect) return this.renderMultiple()

    let key = this.props.data.head.vars[0]
    let label = this.props.data.head.vars[0]
    if (this.props.data.head.vars.length > 1) {
      key = this.props.data.head.vars[1]
    }

    let publishVariable = this.props.publishVariable

    let items = restructureResponse(this.props.data)
    //console.log(key,items);
    let selectedItem = this.props.pubsub[publishVariable]

    return (
      <div className={styles.main}>
        {this.props.title && (
          <div className={styles.title}>{this.props.title}</div>
        )}
        <ul className={styles.main}>
          {items.map((item, index) => {
            if (publishVariable) {
              return (
                <li key={index}>
                  <Button
                    color="link"
                    className={
                      selectedItem === item[key] ? styles.selected : ''
                    }
                    style={{ 'text-align': 'left', padding: '0px' }}
                    onClick={() => {
                      this.props.publish(publishVariable, item[key])
                      PublishUtils.resetParameter(this)
                    }}
                  >
                    {item[label]}
                  </Button>
                </li>
              )
            } else {
              return (
                <li key={index}>
                  <div style={{ padding: '2px 1px 1px' }}>{item[label]}</div>
                </li>
              )
            }
          })}
        </ul>
      </div>
    )
  }
}

export default List
