import React, { Component } from 'react'

export default class BBParameterLinkEmptyWidget extends Component {

  static getDescription() {
    return {
      name: 'BBParameterLinkEmptyWidget',
      component: BBParameterLinkEmptyWidget,
      label: 'Parameter Link (EmptyWidget)',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'Query to link a parameter',
        },
        {
            name: 'publishParameter',
            type: 'text',
            label: 'publish parameter name (use ?uri single value limit 1)'
         }
        ]
    }

}
shouldComponentUpdate(props,state)
{
  if (props==null) return false;
  if (props.data==null) return false;

  try{
    if (props.data!=this.props.data)
    {
      if (props.data!=null)
      {
       // console.log(props.data);
        if (props.data.results!=null)
        {
        try
        {
           if (props.data.results.bindings==null) return false;
           if (props.data.results.bindings.length!=1) return false;
           


          var newValue=props.data.results.bindings[0].uri.value;
         
          var par=props.publishParameter;
          if (par!=null)
          {
           
            this.props.publish(par,newValue);
           // console.log("perhaps publish new results? ",newValue,par);
          }
        }
        catch(ex)
        {
         // console.log(ex);
        }
      }
      }

    }
  }
  catch(e){
  //  console.log(e)
  }
    return false;
}


render()
{
 return null;   
}

}


