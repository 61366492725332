import { lazy } from 'react';
export default class BBAccordionDescriptor  {

static getDescription() {
    return {
      name: 'Accordion',
      component: lazy(() =>  import('./BBAccordion')),
      label: 'Accordion',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?label for accordion headings. use ?content for inner accordion content ',
        },

        {
          name: 'title',
          type: 'text',
          label: 'Titel boven de tabel',
        },

        {
          name: 'html',
          type: 'boolean',
          label: 'html text',
        },
      ],
    }
  }
}
