import React from 'react'
import styles from './Tab.module.css'
import Area from '../Area'
import { Icon } from './Icon'

class Tab extends React.Component {
  static tabId = 0
  state = { selectedIndex: 0, hideTabs: null, areas: [], id: Tab.tabId++ }

  static getDescription() {
    return {
      name: 'Tab',
      component: Tab,
      label: 'Tab Layout',
      variables: [
        {
          name: 'tabs',
          type: 'array',
          label: 'Tabs',
        },
        {
          name: 'tabSelectionSubscription',
          type: 'text',
          label: 'Variabele to listen to for tab selection',
        },
        {
          name: 'hideTabIDs',
          type: 'array',
          label:
            'tab name (ids) that can be used to hide a tab. If not available the tabname will be used',
        },
        {
          name: 'hideTabs',
          type: 'text',
          label:
            'Variabele to listen to for tab visualisation. Use tab names to hide the tabs',
        },
        {
          name: 'icons',
          type: 'array',
          label: 'fa icon names when no icon is defined. ',
        },
        {
          name: 'inactiveTabsOnlyIcons',
          type: 'boolean',
          label: 'Inactive tabs will only have icons if icons are defined'
        },
        {
          name: 'initialTabSelection',
          type: 'text',
          label: 'index of the initial selected tab',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
      ],
    }
  }

  static registeredTabComponents = [] //registering all tabs for 'back button'

  getTabId() {
    return this.state.id
  }

  componentDidMount2() {
    if (this.props.initialTabSelection != null) {
      try {
        // var index = parseInt(this.props.initialTabSelection+"");
        let index = this.props.initialTabSelection
        this.setState({ selectedIndex: index })
      } catch (e) {}
    }
    Tab.registeredTabComponents.push(this)
  }
  getFirstNonHiddenTab(previoustabname) {
   // console.log("previous tabname "+previoustabname);
    let selectedIndex = 0
    let hideTabs = this.props.hideTabs
    let data = this.props.pubsub[hideTabs]
    if (data==null) data="";
    if (data.includes(",")) {data=data.split(",")} else {data = [data];} 
    let firstResult=null;
    let betterResult=null;
    for (let n in this.props.tabs) {
      let tab = this.props.tabs[n]
      try {tab =  this.props.hideTabIDs[n] ;} catch(e){console.log(n,e)}
       var result=true;
      for (let nn in data)
      {
            let datan=data[nn];
          if (tab == datan) {
            result=false;
          }
      }
      if (result)
      {
        if (firstResult==null)
        {
          firstResult=n;
        }
        if (previoustabname!=null)
        {
          try
          {
               if ((betterResult==null) && (previoustabname.substring(0, 5)==tab.substring(0,5)) )
               {
                   return n;
               }
          }
          catch(e){}
        }
       // console.log(tab);
        //return n;
      }

    }
    if (firstResult!=null) return firstResult;

    return selectedIndex
  }

  componentDidUpdate(prevProps) 
  {
    let tabSelectionSubscription = this.props.tabSelectionSubscription
    if (tabSelectionSubscription) {
      // console.log("tab did update ",this.state);
      if (
        prevProps.pubsub[tabSelectionSubscription] !==
        this.props.pubsub[tabSelectionSubscription]
      ) {
        let index = this.props.pubsub[tabSelectionSubscription]
        //index=parseInt(index+"");
       //   console.log("set selected index change  1 ",index);
        this.setState({ selectedIndex: index })
      }
    }
    let hideTabs = this.props.hideTabs
    if (hideTabs) {
      if (prevProps.pubsub[hideTabs] !== this.props.pubsub[hideTabs]) {
        
        let data = this.props.pubsub[hideTabs]
        if (data==null) data="";

        if (data.includes(",")) {data=data.split(",")} else {data = [data];} 

        let selectedIndex = this.state.selectedIndex
        let selectedTabname = this.props.tabs[selectedIndex];
        
          try {selectedTabname = this.props.hideTabIDs[selectedIndex];} catch(e){}
        for (let nn in data)
        {
          let datan=data[nn];
        
        if (selectedTabname == datan) {
          
          selectedIndex = this.getFirstNonHiddenTab(selectedTabname)
       //   console.log("trying to find the first NonHiddentab ",selectedIndex);
          selectedIndex = parseInt(selectedIndex + '')
         
          this.onTabClick(selectedIndex)
        }
      }
      //   console.log("set selected index change ",selectedIndex);
        this.setState({ hideTabs: data, selectedIndex: selectedIndex })
      }
    }
  }

  onTabClick = (index) => {
    let tabSelectionSubscription = this.props.tabSelectionSubscription

    if (tabSelectionSubscription) {
      var me = this
      me.props.publish(tabSelectionSubscription, index)
    } else {
    }
    this.setState({ selectedIndex: index })
  }

  render() {
    let mode = this.props.mode
    return (
      <div
        className={styles.main + (mode === 'edit' ? '' : ' ' + styles.viewMode) + ' ' + styles[this.props.panelstyle]}
      >
        <div className={styles.tabs}>
          {this.props.tabs.map((tabText, index) => {
            let selected = index == this.state.selectedIndex
            var icons = this.props.icons
            if (this.state.hideTabs != null) {
              let data=this.state.hideTabs;
           
              for (let nn in data)
              {
                let datan=data[nn];

                   let tab2=tabText;
                   try {tab2 =  this.props.hideTabIDs[index] ;} catch(e){console.log(index,e)}
                  
              if (tab2 == datan) {
               // console.log("not showing tab ",tab,tab2);
                return null
              }
            }
            }
            const hideTabText = this.props.inactiveTabsOnlyIcons && icons && !selected

            return (
              <div
                key={index}
                className={styles.tab + (selected ? ' ' + styles.selected : '')}
                onClick={() => this.onTabClick(index)}
              >
                {icons != null && (
                  <Icon
                    icon={icons[index]}
                    size="xs"
                    transform="down-0; left-4"
                  />
                )}
                {!hideTabText && tabText}
              </div>
            )
          })}
        </div>

        {this.props.tabs.map((tab, index) => {
          let areaConfig = this.props.areas[index] || {}

          let selected = index == this.state.selectedIndex
          // console.log(index,this.state.selectedIndex,selected);
          if (this.props.withinSelectedTab != null) {
            if (this.props.withinSelectedTab == false) {
              selected = false
              //  console.log("TEST FF NIET SELECTED TAB WANT TAB IS NIET ZICHTBAAR");
            }
          }

          return (
            <div
              key={index}
              className={styles.areaContainer}
              style={{ display: selected ? 'flex' : 'none' }}
            >
              <Area
                {...this.props}
                key={index}
                index={index}
                config={areaConfig}
                onChange={this.props.onChange}
                withinSelectedTab={selected}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

export default Tab
