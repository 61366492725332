
import { lazy } from 'react';

export default class BBStructuredTextDescriptor{


static getDescription() {
    return {
      name: 'BBStructuredText',
      component: lazy(() =>  import('./BBStructuredText')),
      label: 'BB Structured Text',
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label: 'use parameter ?header ?headerText ?content  ',
        },
        {
          name: 'paperbackground',
          type: 'select',
          label: 'paper background',
          options: [
            {
              label: 'default',
              value: 'noneWhite',
            },
            {
              label: 'grey',
              value: 'bbgrey',
            },
            {
              label: 'white',
              value: 'bbwhite',
            },
            {
              label: 'default grey',
              value: 'noneGrey',
            },
          ],
        },
        {
          name: 'papercolor',
          type: 'select',
          label: 'paper color',
          options: [
            {
              label: 'default',
              value: 'noneWhite',
            },
            {
              label: 'white paper',
              value: 'bbwhitePaper',
            },
            {
              label: 'grey paper',
              value: 'bbgreyPaper',
            },

            {
              label: 'default grey',
              value: 'noneGrey',
            },
          ],
        },

        {
          name: 'cls',
          type: 'text',
          label: 'class ',
        },
      ],
    }
  }
}